import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Link } from "gatsby";
import useDeviceDetect from '@blocks/detect-device/detect-device';
import PlayIcon from '@components/play-icon/play-icon';
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";
import ReactPlayer from 'react-player';
import AreaBasedAreaguide from './get-area-guide-by-area'

const SINGLE_AREA = gql`
query GetTeamDetails ($Prop_Postcode: String!){
    areaGuides (where:{Post_Code_contains:$Prop_Postcode}) {
      URL   
      Title
      Embed_Video_URL   
      Tile_Image {
        url
      }
    }
  
}
`;



function TeamDetails(props) {

  const [show, setShow] = useState(false);
  const { isMobile } = useDeviceDetect();
  const [controls, setVideoControls] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const vidRef = useRef(null);
  const [showVideo, setShowVideo] = React.useState(false);
  const [isPlay,setPlay] = React.useState(false);
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
}
const showControls = () => {
    setVideoControls(true)
}
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': url,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }

  const { loading, error, data } = useQuery(SINGLE_AREA, {
    variables: { Prop_Postcode: props.postcode }
  });
  if(data && data.areaGuides && data.areaGuides.length > 0) {
  if (loading) return <p>Loading ...</p>;

  const setAreaGuide = []
  data &&
    data.areaGuides &&
    data.areaGuides.map(node => {
      if (props?.display_address?.toLowerCase()?.includes(node?.Title?.toLowerCase())) {
        setAreaGuide.push(node)
      }
    })

  const areaGuides = setAreaGuide.length > 0 ? setAreaGuide : data.areaGuides;

  return (
    <>
      {areaGuides && areaGuides.map((node, key) => {
        if(key == 0) {
        return <>
        <div className="guide-video">
        <div className="vide-wrapper video-box">
          <img src={node.Tile_Image.url}/>
            <div className="sep-play">
              <a onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                <PlayIcon />
              </a>
            </div>           
              <>             
              {showHTMLVideo &&
              <Modal
              show={show}
              onHide={() => { setHTMLVideo(false); playvideo(); setShow(false); }}
              dialogClassName="modal-video"
              aria-labelledby="example-custom-modal-styling-title"
              backdropClassName="video-backdrop"
              >
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body>
                  <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(node.Title)} url={node.Embed_Video_URL}  controls={true} autoplay={true} frameborder="0" muted={false} playsinline={true} playing={play} onEnded={() => { setShowVideo(false); }} width='100%' height='100%' />
                  </Modal.Body>
              </Modal>
              }
              </>          
        </div>
        </div>
        <div className='area-guide-text'>
          <h5 className='text-24 bold'>
            {/*Located in {props.address1}, {props.address2} */}
            Local Area Guide
          </h5>
          <ul>
            <li><span><a href="javascript:;" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>Play {node.Title} End Video</a></span></li>
            <li className='divid'>/</li>
            <li><span><Link to={`/about/area-guide/${node && node.URL}/`}>{node.Title} Area Guide</Link></span></li>
            </ul>
        </div>
        </>
        }
      })}
    </>
  )
} else {
return (
<AreaBasedAreaguide address1={props.address1} address2={props.address2} area={props.area} />
)
}
}

export default TeamDetails
