import React, { useState, useRef } from 'react';
import { Container,Tab,Col,Row,Nav } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import PersonVideo from '@components/person-video/person-video';
import { Link } from "gatsby";
import { Logo } from '@components/icon/icon';
import PlayIcon from '@components/play-icon/play-icon';
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';

import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
/**
 * Assets
 */
import './styles/_index.scss';

function VideosPage(props) {
    const [showVideo, setShowVideo] = React.useState(false);
    const [videoindex, setVideoindex] = React.useState('default');
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const [play, setPlayvideo] = useState(true);
    const [mute, setMute] = useState(true);
    const vidRef = useRef(null);

    const [isPlay,setPlay] = React.useState(false);
      const playVideo = (video_index, video_id) => {
        setVideoindex(video_index)   
        setShowVideo(true)
      }
      const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': url,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
      }
  
  
    return (
        <>
        <div className="static-person-video area-section video-listing-page team-listing-intro spacing-40 pb-0">
            <Container>                
                <div className="slider-holder row">
                {props.data.edges.map(({node},index) => {
                    var incekey =  index+1;
                    var delaying = incekey+'00'; 
                    if(node.Embed_Video_URL !=null && node.Embed_Video_URL != '') {
                        var videoid = getVideoId(node.Embed_Video_URL);
                    } 
                    // let processedImages = JSON.stringify({});
                    // if (node?.imagetransforms?.Video_Image_Transforms) {
                    //     processedImages = node.imagetransforms.Video_Image_Transforms;
                    // }
                        const image_url = node.Video_Image.internal.description ? node.Video_Image.internal.description.replace("File ","").replace('"','').replace('"','') : '';  
                        return <>               
                        <div className = "col-lg-4 col-md-6 block-wrap">
                        <ScrollAnimation offset={!isMobile?1000:''} delay={delaying} animateIn="animate__fadeInUp" animateOnce={true}>                                  
                            <div className="person-video">
                            <div className="vide-wrapper video-box">
                            <Link to={node?.URL ? node.URL+'/' : '#'}>
                            {/* <ImageTransform imagesources={image_url} renderer="srcSet" imagename="our-videos.Video_Image.commontile"attr={{ alt: node.Title+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={node.strapiId}/> */}
                            <ImageRenderer ImageSrc={{url:image_url}} altText={node.Title+ ' - Anthony Pepe'} ggfx_results={node?.ggfx_results} strapi_id={node.strapiId}  imagename="our-videos.Video_Image.commontile" />
                            </Link>
                           
                            {showVideo && videoindex== index &&
                            <>
                            <div className="videoBTNClose">
                                <a onClick={e => {setPlay(false);setShowVideo(false);setVideoindex('default');}}></a>
                            </div>
                            <ReactPlayer ref={vidRef} onPlay={trackerVideo(props.Title)} url={node.Embed_Video_URL}  controls={true} autoplay={true} muted={false} playsinline={true} playing={play} onEnded={() => { setShowVideo(false);setVideoindex('default'); }} width='100%' height='100%' />
                            </>
                            }
                            </div>
                            <div className="testimonial-title spacing-24"><Link to={node?.URL ? node.URL+'/' : '#'}><h4 className="fcaps">
                            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={node.Title.replace(/^[^a-zA-Z]*([a-zA-Z])/g, '<span class="big-cap">$1</span>')}/>
                            </h4></Link></div>
                            {node.Embed_Video_URL !=null && node.Embed_Video_URL != '' && videoindex != index &&
                                <a href="javascript:;" onClick = { (e) => {playVideo(index, videoid.id)}}>
                                <PlayIcon />
                                </a>
                            }
                            
                            </div>
                        </ScrollAnimation>
                        </div>
                        </>
                    })}                        
                </div>        
            </Container>
        </div>

        </>
    )

                }
export default VideosPage
