import React, { useEffect, componentDidMount } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import Video from '@components/video/video';
import TextColumns from '@components/text-columns/text-columns';
import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import LandingAccordion from '@components/landing-accordion/landing-accordion';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import ImgBox from '@components/img-box/img-box'
import { useStaticQuery, graphql, Link } from "gatsby";
import { Logo, Phone } from '@components/icon/icon';
import { Container } from 'react-bootstrap';


const SINGLE_TEAM = gql`
query GetTeamDetails ($CRM_Office_ID: String!){
  offices (where:{CRM_Office_ID:$CRM_Office_ID}) {
    Title
    URL
    Phone_Number
    CRM_Office_ID
  }
}
`;



function TeamDetails(props) {
  //console.log("office data", props.officecrmid)
  const { loading, error, data } = useQuery(SINGLE_TEAM, {
    variables: { CRM_Office_ID: props.officecrmid }
  });


  //console.log("office data", data)
  //console.log("office data", data)
  if (loading) return <p>Loading ...</p>;
    
  return (
    <>
      {data.offices.map((node, key) => {
        if(key == 0) {
        return <>
		    <h6>Call <Link to={`/contact/our-offices/${node.URL}/`}>{node.Title}</Link></h6>
        <h5>
          <a className='h5 yellow' href={`tel:${node.Phone_Number}`}><span>{node.Phone_Number}</span></a>
          <a className='h5 yellow btn respnsive' href={`tel:${node.Phone_Number}`}><Phone className="respnsive" /> <span className='text-20 bold'>{node.Phone_Number}</span><span className='mobiel-view text-20 bold'>Call</span></a>
        </h5>
        </>
        }
      })}
    </>
  )
}

export default TeamDetails
